@import '../../styles/variables.scss';
.cardscreen {
    h3 {
        color: $kleur-primair-abp-blauw;
        padding: 156px 30px 12px 0px;
    }
    h6 {
        color: $grijstint-zwart;
        padding: 0px 30px 30px 0px;
    }
    .cards {
        margin: 16px 30px 48px 30px;
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: max-content max-content;
        justify-content: center;
        gap: 48px;

        .align-at-end {
            grid-column-start: 4;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 2;
        }
    }
    .button {
        clear: both;
        float: right;
        margin-right: 21px;
        min-width: 141px;
    }
}

@media (max-width: 1200px) {
    .cardscreen {
        .cards {
            grid-template-columns: auto auto;
            grid-template-rows: max-content max-content auto;

            .align-at-end {
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 3;
                grid-row-end: 3;
            }
        }
    }
}

@media (max-width: 860px) {
    .cardscreen {
        h3 {
            padding: 130px 30px 15px 0px;
        }
    }
}

@media (max-width: 700px) {
    .cardscreen {
        h3 {
            padding: 156px 30px 15px 0px;
            font-size: 30px;
        }
        h6 {
            padding: 9px 30px 15px 0px;
            font-size: 15px;
        }
        .cards {
            grid-template-columns: auto;
            grid-template-rows: max-content max-content max-content max-content auto;

            .align-at-end {
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 5;
                grid-row-end: 5;
            }
        }
    }
}

@media (max-width: 430px) {
    .cardscreen {
        h3 {
            font-size: 21px;
        }
        h6 {
            font-size: 14px;
        }
    }
}

@media (max-width: 380px) {
    .cardscreen {
        h3 {
            padding: 120px 30px 15px 0px;
        }
    }
}
