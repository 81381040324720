@import '../../styles/variables.scss';

.introscreen {
    h1 {
        color: $kleur-primair-abp-blauw;
        padding: 156px 30px 0px 0px;
    }
    h3 {
        color: $kleur-primair-abp-blauw;
        padding: 32px 30px 0px 0px;
    }
    p, li {
        color: $grijstint-zwart;
    }
    ol {
        margin: 0px;
        padding-left: 0px;
    }
    .button {
        float: right;
        min-width: 141px;
        clear: both;
    }
    .start {
        display: flex;
        justify-content: flex-end;
        padding-top: 16px;
        padding-bottom: 48px;
    }
}

@media (max-width: 860px) {
    .introscreen {
        h1 {
            padding: 130px 30px 15px 0px;
        }
    }
}

@media (max-width: 700px) {
    .introscreen {
        h1 {
            padding: 156px 30px 15px 0px;
            font-size: 30px;
        }
        h3 {
            padding: 9px 30px 0px 0px;
            font-size: 21px;
        }
        p, li {
          font-size: 15px;
        }
    }
}

@media (max-width: 430px) {
    .introscreen {
        h1 {
            font-size: 21px;
        }
        h3 {
            font-size: 16px;
        }
        p, li {
           font-size: 14px;
        }
    }
}

@media (max-width: 380px) {
    .introscreen {
        h1 {
            padding: 120px 30px 15px 0px;
        }
    }
}
