@import url(https://fonts.googleapis.com/css?family=Open+Sans);
button {
  padding: 8px 24px;
  border-radius: 2px 2px 8px 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18.66px;
  line-height: 130%;
  -webkit-font-feature-settings: 'pnum' on, 'lnum' on;
          font-feature-settings: 'pnum' on, 'lnum' on; }
  button .icon {
    width: 16px;
    height: 16px; }

.button-primary {
  position: relative;
  -webkit-appearance: none;
  background: #ED7102;
  border: 1px solid #ED7102;
  color: #fff;
  cursor: pointer;
  box-sizing: content-box; }
  .button-primary .mail polyline {
    stroke: #ED7102; }

.button-primary:hover {
  background: #A7358B;
  border: 1px solid #A7358B; }
  .button-primary:hover .mail polyline {
    stroke: #A7358B; }

.button-secondary {
  position: relative;
  -webkit-appearance: none;
  background: #009CBD;
  border: 1px solid #009CBD;
  color: #fff;
  cursor: pointer;
  box-sizing: content-box;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.button-secondary:hover {
  background: #0057A3;
  border: 1px solid #0057A3;
  color: #FFFFFF; }
  .button-secondary:hover svg polyline {
    stroke: #FFFFFF; }

.button-primary-ghost {
  position: relative;
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid #ED7102;
  color: #000000;
  cursor: pointer;
  box-sizing: content-box;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.button-primary-ghost:hover {
  background: #A7358B;
  border: 1px solid #A7358B;
  color: #FFFFFF; }
  .button-primary-ghost:hover svg polyline {
    stroke: #FFFFFF; }

.button-secondary-ghost {
  position: relative;
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid #009CBD;
  color: #000000;
  cursor: pointer;
  box-sizing: content-box;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

.button-secondary-ghost:hover {
  background: #0057A3;
  border: 1px solid #0057A3;
  color: #FFFFFF; }
  .button-secondary-ghost:hover svg polyline {
    stroke: #FFFFFF; }

button.right {
  padding: 8px 16px 8px 24px; }
  button.right .pijl {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

button.left {
  padding: 8px 24px 8px 16px;
  flex-direction: row-reverse !important; }
  button.left .pijl {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

button.greyedOut {
  opacity: 0.5;
  pointer-events: none; }

button.icon-flipped svg {
  -webkit-transform: none !important;
          transform: none !important; }

.splashscreen {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
  max-width: 800px;
  margin: 0 auto; }
  .splashscreen h1 {
    max-width: 90%;
    margin: 0 auto;
    padding: 45px 30px 8px 30px;
    color: #0057A3; }
  .splashscreen h2 {
    max-width: 90%;
    margin: 0 auto 45px auto;
    padding: 0 30px;
    color: #009CBD; }
  .splashscreen h6 {
    max-width: 90%;
    margin: 0 auto 45px auto;
    padding: 0 30px;
    color: #000000; }
  .splashscreen .button-pair {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
    gap: 16px; }

@media (max-width: 600px) {
  .splashscreen h1 {
    font-size: 45px; }
  .splashscreen h2 {
    font-size: 21px; } }

@media (max-width: 400px) {
  .splashscreen h1 {
    font-size: 36px; }
  .splashscreen h2 {
    font-size: 18px; } }

@media (max-height: 600px) {
  .splashscreen h1 {
    font-size: 36px; }
  .splashscreen h2 {
    font-size: 18px; } }

@media (max-height: 400px) {
  .illustration {
    display: none; } }

.header {
  position: fixed;
  min-height: 100px;
  z-index: 2;
  top: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.03);
  border-radius: 0px 0px 32px 4px;
  display: grid;
  grid-template-columns: 160px auto 160px;
  grid-template-rows: 100px; }
  .header a {
    text-decoration: none; }
  .header .center {
    margin: 0 auto;
    align-self: center; }
  .header .title {
    text-align: center;
    padding: 16px;
    margin: 0;
    color: #000000; }
  .header .logo-abp {
    fill: #fff;
    height: 48px;
    width: 160px;
    align-self: center;
    margin-left: 8px; }
  .header .infobutton {
    width: 160px;
    cursor: pointer;
    fill: #009CBD;
    align-self: center; }
    .header .infobutton svg {
      width: 60px;
      float: right;
      margin-right: 8px; }
  .header .icon {
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .header .icon:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

@media (max-width: 860px) {
  .header .title {
    padding: 15px 30px 15px 30px; } }

@media (max-width: 700px) {
  .header {
    grid-template-columns: 80px auto 80px; }
    .header .title {
      padding: 16px;
      font-size: 18px; }
    .header .logo-abp {
      height: 24px;
      width: 80px; }
    .header .infobutton {
      width: 80px; } }

@media (max-width: 430px) {
  .header {
    grid-template-columns: 60px auto 60px; }
    .header .title {
      font-size: 15px; }
    .header .logo-abp {
      height: 12px;
      width: 60px;
      margin-left: 0; }
    .header .infobutton {
      width: 60px; }
      .header .infobutton svg {
        height: 36px;
        margin-right: 0; } }

.sharebuttons {
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 156px;
  right: -186px;
  height: 45px;
  background-color: #009CBD;
  border-radius: 6px 0 0 6px;
  z-index: 3;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2); }
  .sharebuttons span {
    display: inline-block;
    width: 45px;
    height: 45px;
    cursor: pointer; }
  .sharebuttons .mail rect {
    fill: #009CBD; }
  .sharebuttons .mail polyline {
    fill: #009CBD;
    stroke: #fff;
    stroke-width: 2px; }
  .sharebuttons .socialbutton {
    background-color: #fff;
    border-left: 1px solid #009CBD; }
    .sharebuttons .socialbutton .icon {
      transition: all 0.1s ease-in-out; }
  .sharebuttons .socialbutton:hover .icon {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

.sharebuttons.open {
  right: 0px; }

.introscreen h1 {
  color: #0057A3;
  padding: 156px 30px 0px 0px; }

.introscreen h3 {
  color: #0057A3;
  padding: 32px 30px 0px 0px; }

.introscreen p, .introscreen li {
  color: #000000; }

.introscreen ol {
  margin: 0px;
  padding-left: 0px; }

.introscreen .button {
  float: right;
  min-width: 141px;
  clear: both; }

.introscreen .start {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
  padding-bottom: 48px; }

@media (max-width: 860px) {
  .introscreen h1 {
    padding: 130px 30px 15px 0px; } }

@media (max-width: 700px) {
  .introscreen h1 {
    padding: 156px 30px 15px 0px;
    font-size: 30px; }
  .introscreen h3 {
    padding: 9px 30px 0px 0px;
    font-size: 21px; }
  .introscreen p, .introscreen li {
    font-size: 15px; } }

@media (max-width: 430px) {
  .introscreen h1 {
    font-size: 21px; }
  .introscreen h3 {
    font-size: 16px; }
  .introscreen p, .introscreen li {
    font-size: 14px; } }

@media (max-width: 380px) {
  .introscreen h1 {
    padding: 120px 30px 15px 0px; } }

.modal {
  pointer-events: none; }
  .modal .overlay {
    z-index: 100;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    min-height: 100vh;
    background-color: #333;
    transition: opacity 0.3s ease; }
  .modal .panel {
    z-index: 101;
    max-height: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 3px 3px 36px 3px;
    overflow: hidden;
    max-width: 0;
    transition: all 0.3s ease;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2); }
    .modal .panel .title-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .modal .panel .title-bar h3 {
        margin: 0;
        padding: 24px 24px 12px 24px; }
      .modal .panel .title-bar .kruis {
        padding: 18px 18px 0px 0px;
        width: 45px;
        height: 45px;
        stroke: #282D32;
        cursor: pointer; }
        .modal .panel .title-bar .kruis line {
          transition: all 0.3s ease;
          -webkit-transform-origin: 50% 50%;
                  transform-origin: 50% 50%; }
      .modal .panel .title-bar .kruis:hover .lijn1 {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .modal .panel .title-bar .kruis:hover .lijn2 {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
    .modal .panel .content {
      padding: 24px; }
    .modal .panel p {
      margin: 0;
      padding: 0 0 12px 0;
      color: #282D32; }
    .modal .panel a {
      color: #0057A3;
      font-weight: 600; }

.modal.open {
  pointer-events: all; }
  .modal.open .overlay {
    opacity: 0.8; }
  .modal.open .panel {
    max-height: calc(100% - 100px);
    max-width: 100%;
    overflow-y: auto; }

@media (max-width: 720px) {
  .modal .panel {
    min-width: 90%; }
    .modal .panel h3 {
      font-size: 18px; }
    .modal .panel p {
      font-size: 15px; } }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
  width: 240px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px 3px 36px 3px;
  cursor: pointer;
  text-align: center; }
  .card h5 {
    height: 48px;
    font-size: 24px;
    padding: 24px;
    margin: 0;
    border-radius: 9px 9px 0 0;
    color: #0057A3;
    font-weight: 400;
    line-height: 1; }
  .card p {
    font-size: 16px;
    margin: 12px; }
  .card .vinkje {
    position: absolute;
    top: 75px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 150px; }
  .card button {
    margin-bottom: 24px; }

@media (max-width: 700px) {
  .card h5 {
    height: 36px;
    font-size: 18px; }
  .card p {
    font-size: 14px; } }

@media (max-width: 470px) {
  .card h5 {
    height: 24px; } }

.cardscreen h3 {
  color: #0057A3;
  padding: 156px 30px 12px 0px; }

.cardscreen h6 {
  color: #000000;
  padding: 0px 30px 30px 0px; }

.cardscreen .cards {
  margin: 16px 30px 48px 30px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: -webkit-max-content -webkit-max-content;
  grid-template-rows: max-content max-content;
  justify-content: center;
  grid-gap: 48px;
  gap: 48px; }
  .cardscreen .cards .align-at-end {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 2; }

.cardscreen .button {
  clear: both;
  float: right;
  margin-right: 21px;
  min-width: 141px; }

@media (max-width: 1200px) {
  .cardscreen .cards {
    grid-template-columns: auto auto;
    grid-template-rows: -webkit-max-content -webkit-max-content auto;
    grid-template-rows: max-content max-content auto; }
    .cardscreen .cards .align-at-end {
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 3; } }

@media (max-width: 860px) {
  .cardscreen h3 {
    padding: 130px 30px 15px 0px; } }

@media (max-width: 700px) {
  .cardscreen h3 {
    padding: 156px 30px 15px 0px;
    font-size: 30px; }
  .cardscreen h6 {
    padding: 9px 30px 15px 0px;
    font-size: 15px; }
  .cardscreen .cards {
    grid-template-columns: auto;
    grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content auto;
    grid-template-rows: max-content max-content max-content max-content auto; }
    .cardscreen .cards .align-at-end {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 5;
      grid-row-end: 5; } }

@media (max-width: 430px) {
  .cardscreen h3 {
    font-size: 21px; }
  .cardscreen h6 {
    font-size: 14px; } }

@media (max-width: 380px) {
  .cardscreen h3 {
    padding: 120px 30px 15px 0px; } }

.steeg .label {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  width: 100px;
  position: relative; }

.steeg label {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 80px; }
  .steeg label input[type="checkbox"] {
    opacity: 0;
    padding: 15px; }

.steeg .checkbox {
  background: #f4f4f4;
  border-radius: 2px;
  border: 2px solid #ACAEB0;
  display: block;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  width: 15px;
  height: 15px;
  pointer-events: none;
  -webkit-appearance: none;
          appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1); }
  .steeg .checkbox::before {
    position: absolute;
    content: '';
    display: block;
    left: 4px;
    width: 4px;
    height: 10px;
    border-style: solid;
    border-color: #FFFFFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0; }

.steeg .checkbox.checked {
  color: #FFFFFF;
  background-color: #0057A3;
  border-color: #0057A3; }
  .steeg .checkbox.checked::before {
    opacity: 1; }
  .steeg .checkbox.checked ~ label::before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }

.steeg .checkbox:focus {
  outline: none;
  border-color: #0057A3; }

.straat {
  position: relative;
  color: #000000;
  overflow: hidden;
  border-bottom: 2px solid #EDF1F2; }
  .straat .border {
    position: absolute;
    width: 60px;
    height: 100%;
    background-color: #CCEBF1; }
    .straat .border .pijl {
      width: 60px;
      height: 80px;
      flex-direction: column !important;
      grid-gap: 0px !important;
      gap: 0px !important;
      background: none;
      padding: 0;
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
      .straat .border .pijl:focus {
        outline: none; }
      .straat .border .pijl .icon {
        width: 24px;
        height: 24px; }
      .straat .border .pijl .top {
        stroke: #fff;
        pointer-events: none; }
      .straat .border .pijl .bottom {
        stroke: #fff;
        pointer-events: none; }
    .straat .border .pijl.open .top {
      transition: all 0.3s ease;
      -webkit-transform: translateZ(0) rotate(90deg);
              transform: translateZ(0) rotate(90deg); }
    .straat .border .pijl.open .bottom {
      transition: all 0.3s ease;
      -webkit-transform: translateZ(0) rotate(270deg);
              transform: translateZ(0) rotate(270deg); }
    .straat .border .pijl.closed .top {
      transition: all 0.3s ease;
      -webkit-transform: translateZ(0) rotate(270deg);
              transform: translateZ(0) rotate(270deg); }
    .straat .border .pijl.closed .bottom {
      transition: all 0.3s ease;
      -webkit-transform: translateZ(0) rotate(90deg);
              transform: translateZ(0) rotate(90deg); }
    .straat .border .pijl:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
  .straat h6 {
    height: 80px;
    line-height: 80px;
    overflow: hidden;
    padding-left: 60px;
    box-sizing: border-box;
    cursor: pointer; }
  .straat h6:last-of-type {
    border-bottom: none; }
  .straat .content {
    padding-left: 60px; }
  .straat .content.closed {
    transition: all 0.3s ease;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    max-height: 0;
    overflow: hidden; }
  .straat .content.open {
    transition: all 0.3s ease;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    max-height: 800px; }
  .straat table {
    margin: 0;
    border-collapse: collapse;
    width: 100%;
    background-color: #fff; }
    .straat table tr {
      height: 80px;
      overflow: hidden; }
      .straat table tr td:first-of-type {
        padding: 6px 12px 6px 20px; }
    .straat table thead tr {
      height: 45px; }
      .straat table thead tr td {
        vertical-align: bottom; }
      .straat table thead tr h6 {
        margin: 0;
        padding: 12px 30px 6px 0;
        font-size: 20px;
        font-weight: 600; }
      .straat table thead tr .label {
        padding-bottom: 6px; }
    .straat table tbody tr td {
      border-top: 1px solid #E2E5E9;
      border-bottom: 1px solid #E2E5E9; }
    .straat table tbody tr:last-of-type td {
      border-bottom: none; }
    .straat table tbody .optionRow {
      height: 30px; }
      .straat table tbody .optionRow td {
        text-align: center; }

.straat:last-of-type {
  border-bottom: none; }

@media (max-width: 700px) {
  .straat h6 {
    font-size: 18px; }
  .straat table tr td:first-of-type {
    padding: 6px 12px 6px 12px; }
  .straat table thead tr h6 {
    font-size: 16px; }
  .straat table .label {
    font-size: 14px; }
  .straat table td {
    font-size: 14px; } }

@media (max-width: 420px) {
  .straat h6 {
    font-size: 15px; }
  .straat table tr td:first-of-type {
    padding: 6px 0 6px 12px; }
  .straat table thead tr h6 {
    font-size: 12px; }
  .straat table .label {
    width: 60px;
    font-size: 12px; }
  .straat table td {
    font-size: 12px; } }

.harmonica {
  border-radius: 6px;
  margin-top: 20px;
  margin-bottom: 50px;
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid #009CBD;
  background-color: #CCEBF1; }
  .harmonica .straat:first-of-type h6 {
    border-top: none; }

.optionscreen h3 {
  color: #0057A3;
  padding: 156px 30px 12px 0px; }

.optionscreen .uitleg {
  color: #000000;
  padding: 0px 30px 30px 0px; }

.optionscreen .wijken {
  margin: 0px; }

.optionscreen .button {
  min-width: 141px; }

.optionscreen .link-span {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 48px; }

@media (max-width: 860px) {
  .optionscreen h3 {
    padding: 130px 30px 15px 0px; } }

@media (max-width: 700px) {
  .optionscreen h3 {
    padding: 156px 30px 15px 0px;
    font-size: 30px; }
  .optionscreen .uitleg {
    padding: 9px 30px 15px 0px;
    font-size: 15px; } }

@media (max-width: 430px) {
  .optionscreen h3 {
    font-size: 21px; }
  .optionscreen .uitleg {
    font-size: 14px; } }

@media (max-width: 380px) {
  .optionscreen h3 {
    padding: 120px 30px 15px 0px; } }

.ResultTable {
  width: 100%;
  border-spacing: 0; }
  .ResultTable thead td {
    background-color: #CCEBF1;
    border: 2px solid #EDF1F2;
    border-bottom: none;
    border-left: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    width: 22%; }
  .ResultTable thead td:first-of-type {
    background-color: transparent;
    width: 34%; }
  .ResultTable thead td:last-of-type {
    border-radius: 0 3px 0 0; }
  .ResultTable tr {
    height: 60px; }
    .ResultTable tr td {
      padding: 0 12px;
      font-size: 18px; }
  .ResultTable .thema td {
    border: 2px solid #EDF1F2;
    border-bottom: none;
    border-left: none;
    background-color: #fff;
    text-align: center; }
  .ResultTable .thema td:first-of-type {
    background-color: #CCEBF1;
    text-align: right; }
  .ResultTable .thema4 td:first-of-type {
    border-radius: 0 0 0 3px; }
  .ResultTable .thema4 td:last-of-type {
    border-radius: 0 0 3px 0; }
  .ResultTable .divider {
    height: 24px; }
  .ResultTable .totals {
    color: #fff; }
    .ResultTable .totals td {
      background-color: #FFFFFF;
      color: #000000;
      text-align: center;
      border-right: 2px solid #EDF1F2; }
    .ResultTable .totals td:first-of-type {
      background-color: #0057A3;
      color: #FFFFFF;
      border-radius: 3px 0 0 3px;
      text-align: right;
      border-left: none; }
    .ResultTable .totals td:last-of-type {
      border-radius: 0 3px 12px 0;
      border-right: none; }

@media (max-width: 700px) {
  .ResultTable tr td {
    font-size: 13px; } }

@media (max-width: 420px) {
  .ResultTable tr td {
    font-size: 12px; } }

.resultscreen h3 {
  color: #0057A3;
  padding: 156px 30px 12px 0px; }

.resultscreen h6 {
  color: #000000;
  padding: 0px 30px 0px 0px; }

.resultscreen a {
  text-decoration: none; }

.resultscreen .wijken {
  margin: 0 60px; }

.resultscreen .button {
  float: left;
  min-width: 141px; }

.resultscreen .results-wrapper {
  max-width: 1000px;
  margin: 48px auto; }

.resultscreen .button-grid {
  display: grid;
  grid-template-columns: 34% 22% 22% 22%;
  grid-template-rows: auto auto;
  grid-row-gap: 48px;
  row-gap: 48px;
  margin-top: 48px; }
  .resultscreen .button-grid .action {
    width: calc(100% - 52px - 36px);
    margin: 0 auto; }
  .resultscreen .button-grid .one {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1; }
  .resultscreen .button-grid .two {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1; }
  .resultscreen .button-grid .three {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1; }
  .resultscreen .button-grid .back {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2; }
  .resultscreen .button-grid .back-button {
    width: calc(100% - 42px); }

@media (max-width: 1060px) {
  .resultscreen .results-wrapper {
    margin: 48px 30px; } }

@media (max-width: 860px) {
  .resultscreen h3 {
    padding: 130px 30px 15px 0px; } }

@media (max-width: 700px) {
  .resultscreen h3 {
    padding: 156px 30px 15px 0px;
    font-size: 30px; }
  .resultscreen h4 {
    padding: 9px 30px 15px 0px;
    font-size: 15px; } }

@media (max-width: 430px) {
  .resultscreen .results-wrapper {
    margin: 48px 8px; }
  .resultscreen .button-grid {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    grid-row-gap: 12px;
    row-gap: 12px;
    margin-top: 48px; }
    .resultscreen .button-grid .action {
      width: calc(100% - 52px - 58px);
      margin: 0 30px; }
    .resultscreen .button-grid .one {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 1; }
    .resultscreen .button-grid .two {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 2;
      grid-row-end: 2; }
    .resultscreen .button-grid .three {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 3;
      grid-row-end: 3; }
    .resultscreen .button-grid .back {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 4;
      grid-row-end: 4; }
    .resultscreen .button-grid .back-button {
      width: calc(100% - 102px);
      margin: 0 30px; } }

@media (max-width: 380px) {
  .resultscreen h3 {
    padding: 128px 30px 15px 0px; } }

.Box {
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 3px 3px 36px 3px;
  overflow: hidden; }
  .Box h3 {
    margin: 0;
    padding: 24px !important;
    color: #0057A3; }
  .Box .BoxContent {
    padding: 0px 24px 24px 24px; }
    .Box .BoxContent h7 {
      font-weight: bold; }
    .Box .BoxContent p {
      margin: 0;
      padding-bottom: 12px; }
    .Box .BoxContent ul {
      margin: 6px 0;
      padding-left: 18px; }
    .Box .BoxContent a {
      color: #333; }

.mailmodal {
  pointer-events: none; }
  .mailmodal .overlay {
    z-index: 100;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    background-color: #333;
    transition: opacity 0.3s ease; }
  .mailmodal .form {
    z-index: 101;
    max-height: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    overflow: hidden;
    max-width: 0; }
    .mailmodal .form .wrapper {
      display: inline-block;
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
      padding: 0;
      border-radius: 9px;
      height: 46px; }
    .mailmodal .form input {
      width: 450px;
      max-width: 80vw;
      height: 60px;
      border: none;
      font-size: 18.66px;
      padding-left: 22px;
      border-radius: 3px 3px 9px 3px;
      background-color: #fff;
      border: 2px solid #fff;
      box-sizing: border-box; }
    .mailmodal .form input::-webkit-input-placeholder {
      color: #bbb; }
    .mailmodal .form input::placeholder {
      color: #bbb; }
    .mailmodal .form button {
      margin: 24px auto; }
    .mailmodal .form .mail {
      margin-right: 6px; }
    .mailmodal .form .melding {
      color: #fff;
      padding-left: 12px; }

.mailmodal.open {
  pointer-events: all; }
  .mailmodal.open .overlay {
    opacity: 0.8; }
  .mailmodal.open .form {
    max-height: 80px;
    max-width: 100%;
    overflow: visible; }

.exitscreen h3 {
  color: #0057A3;
  padding: 156px 30px 12px 0px; }

.exitscreen h6 {
  color: #000000;
  padding: 0px 30px 30px 0px; }

.exitscreen .box-straat-kop {
  padding: 0;
  margin: 12px 0; }

.exitscreen .links {
  width: 60%;
  display: inline-block;
  margin: 0;
  box-sizing: border-box; }

.exitscreen .rechts {
  width: 40%;
  margin-top: 54px;
  padding-left: 30px;
  float: right;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  align-items: flex-end; }

@page {
  size: A4;
  margin: 1.5cm; }

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
    background-color: #fff !important;
    background: #fff !important; }
  .exitscreen .header,
  .exitscreen .buttons,
  .exitscreen .sharebuttons,
  .exitscreen .rechts {
    display: none; }
  .exitscreen h3 {
    margin-top: 0px;
    padding: 0;
    color: #333; }
  .exitscreen .links {
    width: 100%; }
  .exitscreen .Box {
    page-break-inside: avoid; } }

@media (max-width: 860px) {
  .exitscreen h3 {
    padding: 130px 30px 15px 0px; }
  .exitscreen .links {
    width: 100%; }
  .exitscreen .rechts {
    float: none;
    padding: 0;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 48px;
    align-items: center; } }

@media (max-width: 700px) {
  .exitscreen h3 {
    padding: 156px 30px 15px 0px;
    font-size: 30px; }
  .exitscreen h4 {
    padding: 9px 30px 15px 0px;
    font-size: 15px; } }

@media (max-width: 430px) {
  .exitscreen h3 {
    font-size: 21px; }
  .exitscreen h4 {
    font-size: 14px; } }

@media (max-width: 380px) {
  .exitscreen h3 {
    padding: 120px 30px 15px 0px; } }

@font-face {
  font-family: 'TheSerif E2s';
  src: url(../../static/media/TheSerifE2s-8_Bold.35b94b5d.otf); }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #EDF1F2;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  body *:focus {
    outline: 2px dotted #fff; }
  body h1, body h2, body h3, body h4, body h5 {
    font-family: 'TheSerif E2s', serif;
    margin: 0; }
  body h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px; }
  body h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px; }
  body h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px; }
  body h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px; }
  body h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px; }
  body h6 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0; }
  body p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0; }
  body a {
    color: #fff;
    text-decoration: none; }
  body .wrapper {
    position: relative;
    max-width: 800px;
    padding: 0 30px;
    margin: 0 auto; }

