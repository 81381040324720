@import '../../styles/variables.scss';

.resultscreen {
    h3 {
        color: $kleur-primair-abp-blauw;
        padding: 156px 30px 12px 0px;
    }
    h6 {
        color: $grijstint-zwart;
        padding: 0px 30px 0px 0px;
    }
    a {
        text-decoration: none;
    }
    .wijken {
        margin: 0 60px;
    }
    .button {
        float: left;
        min-width: 141px;
    }
    .results-wrapper {
        max-width: 1000px;
        margin: 48px auto;
    }
    .button-grid {
        display: grid;
        grid-template-columns: 34% 22% 22% 22%;
        grid-template-rows: auto auto;
        row-gap: 48px;
        margin-top: 48px;

        .action {
            width: calc(100% - 52px - 36px); // 100% of container - padding - spacing
            margin: 0 auto;
        }

        .one {
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 1;
        }
        .two {
            grid-column-start: 3;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 1;
        }
        .three {
            grid-column-start: 4;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 1;
        }

        .back {
            grid-column-start: 1;
            grid-column-end: 1;
            grid-row-start: 2;
            grid-row-end: 2;
        }

        .back-button {
            width: calc(100% - 42px); // 100% of container - padding
        }
    }
}
@media (max-width: 1060px) {
    .resultscreen {
        .results-wrapper {
            margin: 48px 30px;
        }
    }
}

@media (max-width: 860px) {
    .resultscreen {
        h3 {
            padding: 130px 30px 15px 0px;
        }
    }
}

@media (max-width: 700px) {
    .resultscreen {
        h3 {
            padding: 156px 30px 15px 0px;
            font-size: 30px;
        }
        h4 {
            padding: 9px 30px 15px 0px;
            font-size: 15px;
        }
    }
}

@media (max-width: 430px) {
    .resultscreen {
        .results-wrapper {
            margin: 48px 8px;
        }

        .button-grid {
            grid-template-columns: auto;
            grid-template-rows: auto auto auto auto;
            row-gap: 12px;
            margin-top: 48px;
    
            .action {
                width: calc(100% - 52px - 58px);
                margin: 0 30px;
            }

            .one {
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 1;
                grid-row-end: 1;
            }
            .two {
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 2;
                grid-row-end: 2;
            }
            .three {
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 3;
                grid-row-end: 3;
            }
    
            .back {
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 4;
                grid-row-end: 4;
            }

            .back-button {
                width: calc(100% - 102px);
                margin: 0 30px;
            }
        }
    }
}

@media (max-width: 380px) {
    .resultscreen {
        h3 {
            padding: 128px 30px 15px 0px;
        }
    }
}
