@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@font-face {
  font-family: 'TheSerif E2s';
  src: url('./fonts/TheSerifE2s-8_Bold.otf');
}
@import './styles/variables.scss';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: $grijstint-witgrijs;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  *:focus {
      outline: 2px dotted #fff;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'TheSerif E2s', serif;
    margin: 0;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  h6 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0;
  }

  a {
  	color: #fff;
    text-decoration: none;
  }

  .wrapper {
    position: relative;
    max-width: 800px;
    padding: 0 30px;
    margin: 0 auto;
  }

}
