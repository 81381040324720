@import '../../styles/variables.scss';

button {
    padding: 8px 24px;
    border-radius: 2px 2px 8px 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18.66px;
    line-height: 130%;
    font-feature-settings: 'pnum' on, 'lnum' on;

    .icon {
        width: 16px;
        height: 16px;
    }
}

.button-primary {
    position: relative;
    -webkit-appearance: none;
    background: $kleur-primair-abp-oranje;
    border: 1px solid $kleur-primair-abp-oranje;
    color: #fff;
    cursor: pointer;
    box-sizing: content-box;

    .mail {
        polyline {
            stroke: $kleur-primair-abp-oranje;
        }
    }
}

.button-primary:hover {
    background: $kleur-secundair-paars;
    border: 1px solid $kleur-secundair-paars;

    .mail {
        polyline {
            stroke: $kleur-secundair-paars;
        }
    }
}

.button-secondary {
    position: relative;
    -webkit-appearance: none;
    background: $kleur-primair-abp-petrol;
    border: 1px solid $kleur-primair-abp-petrol;
    color: #fff;
    cursor: pointer;
    box-sizing: content-box;
    transition: transform 0.3s ease;
}

.button-secondary:hover {
    background: $kleur-primair-abp-blauw;
    border: 1px solid $kleur-primair-abp-blauw;
    color: $grijstint-wit;
    svg {
        polyline {
            stroke: $grijstint-wit;
        }
    }
}

.button-primary-ghost {
    position: relative;
    -webkit-appearance: none;
    background: transparent;
    border: 1px solid $kleur-primair-abp-oranje;
    color: $grijstint-zwart;
    cursor: pointer;
    box-sizing: content-box;
    transition: transform 0.3s ease;
}

.button-primary-ghost:hover {
    background: $kleur-secundair-paars;
    border: 1px solid $kleur-secundair-paars;
    color: $grijstint-wit;
    svg {
        polyline {
            stroke: $grijstint-wit;
        }
    }
}

.button-secondary-ghost {
    position: relative;
    -webkit-appearance: none;
    background: transparent;
    border: 1px solid $kleur-primair-abp-petrol;
    color: $grijstint-zwart;
    cursor: pointer;
    box-sizing: content-box;
    transition: transform 0.3s ease;
}

.button-secondary-ghost:hover {
    background: $kleur-primair-abp-blauw;
    border: 1px solid $kleur-primair-abp-blauw;
    color: $grijstint-wit;
    svg {
        polyline {
            stroke: $grijstint-wit;
        }
    }
}

button.right {
    padding: 8px 16px 8px 24px;
    .pijl {
        transform: rotate(180deg);
    }
}

button.left {
    padding: 8px 24px 8px 16px;
    flex-direction: row-reverse !important;
    .pijl {
        transform: rotate(180deg);
    }
}

button.greyedOut {
    opacity: 0.5;
    pointer-events:none;
}

button.icon-flipped {
    svg {
        transform: none !important;
    }
}
