@import "../../styles/variables.scss";

.steeg {
	.label {
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		width: 100px;
		position: relative;
	}

	label {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0px;
		right: 0px;
		width: 100%;
		height: 80px;
		input[type="checkbox"] {
			opacity: 0;
			padding: 15px;
		}
	}

	.checkbox {
		background: #f4f4f4;
		border-radius: 2px;
		border: 2px solid $grijstint-grijs;
		display: block;
		margin: 0 auto;
		position: relative;
		cursor: pointer;
		text-align: center;
		transition: all 250ms ease;
		width: 15px;
		height: 15px;
		pointer-events: none;
		appearance: none;
		outline: 0;
		cursor: pointer;
		transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

		&::before {
			position: absolute;
			content: '';
			display: block;
			left: 4px;
			width: 4px;
			height: 10px;
			border-style: solid;
			border-color: $grijstint-wit;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			opacity: 0;
		}
	}
	.checkbox.checked {
		color: $grijstint-wit;
		background-color: $kleur-primair-abp-blauw;
		border-color: $kleur-primair-abp-blauw;
		&::before {
			opacity: 1;
		}
		~ label::before {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
		}
	}
	.checkbox:focus {
		outline: none;
		border-color: $kleur-primair-abp-blauw;
	}
}
