@import '../../styles/variables.scss';
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    width: 240px;
    height: 300px;
    background-color: #fff;
    border-radius: 3px 3px 36px 3px;
    cursor: pointer;
    text-align: center;

    h5 {
        height: 48px;
        font-size: 24px;
        padding: 24px;
        margin: 0;
        border-radius: 9px 9px 0 0;
        color: $kleur-primair-abp-blauw;
        font-weight: 400;
        line-height: 1;
    }
    p {
        font-size: 16px;
        margin: 12px;
    }
    .vinkje {
        position: absolute;
        top: 75px;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 150px;
    }
    button {
        margin-bottom: 24px;
    }
}

@media (max-width: 700px) {
    .card {
        h5 {
            height: 36px;
            font-size: 18px;
        }
        p {
            font-size: 14px;
        }
    }
}

@media (max-width: 470px) {
    .card {
        h5 {
            height: 24px;

        }
    }
}

