@import '../../styles/variables.scss';

.mailmodal {
    pointer-events: none;
    .overlay {
        z-index: 100;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        background-color: #333;
        transition: opacity 0.3s ease;
    }
    .form {
        z-index: 101;
        max-height: 0;
        position: fixed;
        top: 50%;
        left: 50%;
        width: 80%;
        text-align:center;
        transform: translate(-50%, -50%);
        overflow: hidden;
        max-width: 0;

        .wrapper {
            display: inline-block;
            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
            padding: 0;
            border-radius: 9px;
            height: 46px;
        }
        input {
            width: 450px;
            max-width: 80vw;
            height: 60px;
            border: none;
            font-size: 18.66px;
            padding-left: 22px;
            border-radius: 3px 3px 9px 3px;
            background-color: #fff;
            border: 2px solid #fff;
            box-sizing: border-box;
        }
        input::placeholder {
            color: #bbb;
        }
        button {
            margin: 24px auto;
        }
        .mail {
            margin-right: 6px;
        }
        .melding {
            color: #fff;
            padding-left: 12px;
        }
    }
}

.mailmodal.open {
    pointer-events: all;
    .overlay {
        opacity: 0.8;
    }
    .form {
        max-height: 80px;
        max-width: 100%;
        overflow: visible;
    }
}
