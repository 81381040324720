@import '../../styles/variables.scss';

$height: 100px;
//large
$sides-lg: 160px;
$logo-lg: 48px;
//medium
$sides-md: 80px;
$logo-md: 24px;
//small
$sides-sm: 60px;
$logo-sm: 12px;

.header {
    position: fixed;
    min-height: $height;
    z-index: 2;
    top: 0;
    width: 100%;
    background-color: $grijstint-wit;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.03);
    border-radius: 0px 0px 32px 4px;

    display: grid;
    grid-template-columns: $sides-lg auto $sides-lg;
    grid-template-rows: $height;

    a {
        text-decoration: none;
    }

    .center {
        margin: 0 auto;
        align-self: center;
    }
    .title {
        text-align: center;
        padding: 16px;
        margin: 0;
        color: $grijstint-zwart;
    }
    .logo-abp {
        fill: #fff;
        height: $logo-lg;
        width: $sides-lg;
        align-self: center;
        margin-left: 8px;
    }
    .infobutton {
        width: $sides-lg;
        cursor: pointer;
        fill: $kleur-primair-abp-petrol;
        align-self: center;

        svg {
            width: 60px;
            float: right;
            margin-right: 8px;
        }
    }
    .icon {
        transition: transform 0.3s ease;
    }
    .icon:hover {
        transform: scale(1.2);
    }
}

@media (max-width: 860px) {
    .header {
        .title {
            padding: 15px 30px 15px 30px;
        }
    }
}

@media (max-width: 700px) {
    .header {
        grid-template-columns: $sides-md auto $sides-md;

        .title {
            padding: 16px;
            font-size: 18px;
        }
        .logo-abp {
            height: $logo-md;
            width: $sides-md;
        }
        .infobutton {
            width: $sides-md;
        }
    }
}

@media (max-width: 430px) {
    .header {
        grid-template-columns: $sides-sm auto $sides-sm;

        .title {
            font-size: 15px;
        }
        .logo-abp {
            height: $logo-sm;
            width: $sides-sm;
            margin-left: 0;
        }
        .infobutton {
            width: $sides-sm;

            svg {
                height: $logo-sm * 3;
                margin-right: 0;
            }
        }
    }
}
