@import '../../styles/variables.scss';

.exitscreen {
    h3 {
        color: $kleur-primair-abp-blauw;
        padding: 156px 30px 12px 0px;
    }
    h6 {
        color: $grijstint-zwart;
        padding: 0px 30px 30px 0px;
    }
    .box-straat-kop {
        padding: 0;
        margin: 12px 0;
    }
    .links {
        width: 60%;
        display: inline-block;
        margin: 0;
        box-sizing: border-box;
    }
    .rechts {
        width: 40%;
        margin-top: 54px;
        padding-left: 30px;
        float: right;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-end;
    }
}
        

@page {
    size: A4;
    margin: 1.5cm;
}

@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
        background-color: #fff !important;
        background: #fff !important;
    }
    .exitscreen {
        .header,
        .buttons,
        .sharebuttons,
        .rechts {
            display: none;
        }
        h3 {
            margin-top: 0px;
            padding: 0;
            color: #333;
        }
        .links {
            width: 100%;
        }
        .Box {
            page-break-inside: avoid;
        }
    }
}



@media (max-width: 860px) {
    .exitscreen {
        h3 {
            padding: 130px 30px 15px 0px;
        }
        .links {
            width: 100%;
        }
        .rechts {
            float: none;
            padding: 0;
            width: 100%;
            margin-top: 30px;
            margin-bottom: 48px;
            align-items: center;
        }
    }
}

@media (max-width: 700px) {
    .exitscreen {
        h3 {
            padding: 156px 30px 15px 0px;
            font-size: 30px;
        }
        h4 {
            padding: 9px 30px 15px 0px;
            font-size: 15px;
        }
    }
}

@media (max-width: 430px) {
    .exitscreen {
        h3 {
            font-size: 21px;
        }
        h4 {
            font-size: 14px;
        }
    }
}

@media (max-width: 380px) {
    .exitscreen {
        h3 {
            padding: 120px 30px 15px 0px;
        }
    }
}

