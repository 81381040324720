@import '../../styles/variables.scss';

$afstand: 60px;
$hoogte: $afstand + 20px;

.straat {
    position: relative;
    color: $grijstint-zwart;
    overflow: hidden;
    border-bottom: 2px solid #EDF1F2;

    .border {
        position: absolute;
        width: $afstand;
        height: 100%;
        background-color: $kleur-primair-abp-licht-blauw;

        .pijl {
            width: $afstand;
            height: $hoogte;
            flex-direction: column !important;
            gap: 0px !important;
            background: none;
            padding: 0;
            transition: transform 0.3s ease;

            &:focus {
                outline: none;
            }

            .icon {
                width: 24px;
                height: 24px;
            }
            .top {
                stroke: #fff;
                pointer-events: none;
            }
            .bottom {
                stroke: #fff;
                pointer-events: none;
            }
        }
        .pijl.open {
            .top {
                transition: all 0.3s ease;
                transform: translateZ(0) rotate(90deg);
            }
            .bottom {
                transition: all 0.3s ease;
                transform: translateZ(0) rotate(270deg);
            }
        }
        .pijl.closed {
            .top {
                transition: all 0.3s ease;
                transform: translateZ(0) rotate(270deg);
            }
            .bottom {
                transition: all 0.3s ease;
                transform: translateZ(0) rotate(90deg);
            }
        }
        .pijl:hover {
            transform: scale(1.2);
        }

    }

    h6 {
        height: $hoogte;
        line-height: $hoogte;
        overflow:hidden;
        padding-left: $afstand;
        box-sizing: border-box;
        cursor: pointer;
    }

    h6:last-of-type {
        border-bottom: none;
    }

    .content {
        padding-left: $afstand;
    }

    .content.closed {
        transition: all 0.3s ease;
        transform: translateZ(0);
        max-height: 0;
        overflow: hidden;
    }
    .content.open {
        transition: all 0.3s ease;
        transform: translateZ(0);
        max-height: 800px;
    }

    table {
        margin: 0;
        border-collapse: collapse;
        width: 100%;
        background-color: #fff;

        tr {
            height: $hoogte;
            overflow: hidden;
            td:first-of-type {
                padding: 6px 12px 6px 20px;
            }
        }
        thead {
            tr {
                height: 45px;
                td {
                    vertical-align: bottom;
                }
                h6 {
                    margin: 0;
                    padding: 12px 30px 6px 0;
                    font-size: 20px;
                    font-weight: 600;
                }
                .label {
                    padding-bottom: 6px;
                }
            }
        }
        tbody {
            tr {
                td {
                    border-top: 1px solid $grijstint-subtielgrijs;
                    border-bottom: 1px solid $grijstint-subtielgrijs;
                }
            }
            tr:last-of-type {
                td {
                    border-bottom: none;
                }
            }
            .optionRow {
                height: 30px;
                td {
                    text-align: center;
                }
            }
        }

    }
}

.straat:last-of-type {
    border-bottom: none;
}

@media (max-width: 700px) {
    .straat {
        h6 {
            font-size: 18px;
        }
        table {
            tr {
                td:first-of-type {
                    padding: 6px 12px 6px 12px;
                }
            }
            thead {
                tr {
                    h6 {
                        font-size: 16px;
                    }
                }
            }
            .label {
                font-size: 14px;
            }
            td {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 420px) {
    .straat {
        h6 {
            font-size: 15px;
        }
        table {
            tr {
                td:first-of-type {
                    padding: 6px 0 6px 12px;
                }
            }
            thead {
                tr {
                    h6 {
                        font-size: 12px;
                    }
                }
            }
            .label {
                width: 60px;
                font-size: 12px;
            }
            td {
                font-size: 12px;
            }
        }
    }
}
