@import '../../styles/variables.scss';

.optionscreen {
    h3 {
        color: $kleur-primair-abp-blauw;
        padding: 156px 30px 12px 0px;
    }
    .uitleg {
        color: $grijstint-zwart;
        padding: 0px 30px 30px 0px;
    }
    .wijken {
        margin: 0px;
    }
    .button {
        min-width: 141px;
    }
    .link-span {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 48px;
    }
}

@media (max-width: 860px) {
    .optionscreen {
        h3 {
            padding: 130px 30px 15px 0px;
        }
    }
}

@media (max-width: 700px) {
    .optionscreen {
        h3 {
            padding: 156px 30px 15px 0px;
            font-size: 30px;
        }
        .uitleg {
            padding: 9px 30px 15px 0px;
            font-size: 15px;
        }
    }
}

@media (max-width: 430px) {
    .optionscreen {
        h3 {
            font-size: 21px;
        }
        .uitleg {
            font-size: 14px;
        }
    }
}

@media (max-width: 380px) {
    .optionscreen {
        h3 {
            padding: 120px 30px 15px 0px;
        }
    }
}
