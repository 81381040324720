@import '../../styles/variables.scss';

.harmonica {
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 50px;
    overflow: hidden;
    box-sizing: border-box;

    border: 2px solid $kleur-primair-abp-petrol;
    background-color: $kleur-primair-abp-licht-blauw;

    .straat:first-of-type {
        h6 {
            border-top: none;
        }
    }
}