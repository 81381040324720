@import '../../styles/variables.scss';


.ResultTable {
    width: 100%;
    border-spacing: 0;

    thead {
        td {
            background-color: $kleur-primair-abp-licht-blauw;
            border: 2px solid $grijstint-witgrijs;
            border-bottom: none;
            border-left: none;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            width: 22%;
        }
        td:first-of-type {
            background-color: transparent;
            width: 34%;
        }
        td:last-of-type {
            border-radius: 0 3px 0 0;
        }
    }

    tr {
        height: 60px;
        td {
            padding: 0 12px;
            font-size: 18px;
        }
    }

    .thema {
        td {
            border: 2px solid $grijstint-witgrijs;
            border-bottom: none;
            border-left: none;
            background-color: #fff;
            text-align: center;
        }
        td:first-of-type {
            background-color: $kleur-primair-abp-licht-blauw;
            text-align: right;
        }
    }

    .thema4 {
        td:first-of-type {
            border-radius: 0 0 0 3px;
        }
        td:last-of-type {
            border-radius: 0 0 3px 0;
        }
    }

    .divider {
        height: 24px;
    }

    .totals {
        color: #fff;
        td {
            background-color: $grijstint-wit;
            color: $grijstint-zwart;
            text-align: center;
            border-right: 2px solid $grijstint-witgrijs;
        }

        td:first-of-type {
            background-color: $kleur-primair-abp-blauw;
            color: $grijstint-wit;
            border-radius: 3px 0 0 3px;
            text-align: right;
            border-left: none;
        }
        td:last-of-type {
            border-radius: 0 3px 12px 0;
            border-right: none;
        }
    }
}

@media (max-width: 700px) {
    .ResultTable {
        tr {
            td {
                font-size: 13px;
            }
        }
    }
}

@media (max-width: 420px) {
    .ResultTable {
        tr {
            td {
                font-size: 12px;
            }
        }
    }
}
