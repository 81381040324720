@import '../../styles/variables.scss';

.Box {
	margin-bottom: 30px;
	background-color: #fff;
	border-radius: 3px 3px 36px 3px;
	overflow: hidden;

	h3 {
		margin: 0;
		padding: 24px !important;
		color: $kleur-primair-abp-blauw;
	}

	.BoxContent {
		padding: 0px 24px 24px 24px;

		h7 {
			font-weight: bold;
		}

		p {
			margin: 0;
			padding-bottom: 12px;
		}

		ul {
			margin: 6px 0;
			padding-left: 18px;
		}

		a {
            color: #333;
        }
	}
}

